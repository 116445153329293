export default function AccountDeleteModal({ onClose }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        padding: 40,
      }}
      onClick={onClose}
    >
      <div
        style={{
          width: '640px',
          minHeight: '100%',
          backgroundColor: 'white',
          margin: 'auto',
          zIndex: 1001,
          color: "black",
          padding: 32,
          fontSize: 18,
          borderRadius: 8,
        }}
      >
        <div style={{ display: "flex", marginBottom:16, }}>
          <img
            src={process.env.PUBLIC_URL + "/assets/app logo.png"}
            aria-label="app logo"
            style={{ width: 100 }}
          />
          <div style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginLeft: 16,
          }}>
            <h1 style={{marginTop: 8, marginBottom: 0}}>
              DimOrder
            </h1>
            <h2 style={{marginTop: 8}}>
              #點單 #點止落單咁簡單
            </h2>
          </div>
        </div>
        <h3>帳號刪除步驟</h3>
        <ul>
          <li>請先完成所有訂單的結帳、取餐</li>
          <li>前往會員頁面，點選頭像</li>
          <img
            alt="會員頁面頭像"
            src={process.env.PUBLIC_URL + "/assets/deleteAccount/1.jpg"}
            style={{
              width: '100%',
              margin: "8px 0",
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
            }}
          />
          <li>按下刪除帳號</li>
          <img
            alt="刪除帳號按鈕"
            src={process.env.PUBLIC_URL + "/assets/deleteAccount/2.jpg"}
            style={{
              width: '100%',
              margin: "8px 0",
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: 8,
            }}
          />
          <li>若無法順利刪除請<a href="https://wa.me/85268262032">聯絡客服</a></li>
        </ul>
      </div>
    </div>
  )
}