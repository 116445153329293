import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'

const CustomerWebUrl = {
  test: 'https://test-c.dimorder.com',
  beta: 'https://beta-c.dimorder.com',
  prod: 'https://c.dimorder.com',
}

/** @type {'test' | 'beta' | 'prod'} */
const REACT_APP_ENV = process.env.REACT_APP_ENV ?? 'prod'
const { pathname, search } = window.location
const params = new URLSearchParams(search)

const isDeleteAccount = params.get('deleteAccount') // 網址中有 deleteAccount，留在本頁顯示 AccountDeleteModal
const isMurray = pathname.startsWith('/murray') // 四州便當網址，沒有 web，一定要進 app
const isAppOnly = params.get('appOnly') || isMurray // 網址中有指定一定要進 app 或是四州網址
const hasPathName = pathname !== '' && pathname !== '/' // 網址後面有 /xxx，非 root path
const hasSearch = search !== '' // 有帶 query

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

function init() {
  // 有裝 APP 的人一點開 link 就已經去 APP 了，所以不會進到這裡判斷

  if (isDeleteAccount) {
    // 不跳轉顯示內容
    renderApp()
    return
  }

  if (isAppOnly) {
    // 限制只能用 APP，不管後面帶了什麼參數或什麼網址，沒進入 APP 都顯示 landing
    // 這裡應該是 wing 要記錄使用 appOnly 的次數
    window.location.href = 'https://s.dimorder.com/app'
    return
  }

  if (hasPathName || hasSearch) {
    // 網址有 path 或有帶參數時直接開啟 web 版
    window.location.replace(CustomerWebUrl[REACT_APP_ENV] + pathname + search)
  }

  // 什麼都沒帶，單純顯示 landing
  renderApp()
}

init()